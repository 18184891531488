import React, { useEffect, useState } from 'react';
import { MovieTooltip } from '../../common/MovieTooltip';
import { Link } from '../../../i18n';
import { MoviePoster } from '../MoviePoster';
import { MovieAsset, AssetType, MovieSlideData } from '../../../domain/work';
import { Tooltip } from '../Tooltip';
import { createPortal } from 'react-dom';
import truncate from 'lodash/truncate';
import { OrderButton } from '../../Order';
import { getAssetSize, getAssetFilename } from '../../MovieBrochure/MovieResourcesKit';
import { ActionButton } from '../Button';
import { WithT } from 'i18next';
import * as i18next from 'i18next';
import sumBy from 'lodash/sumBy';
import compact from 'lodash/compact';
import first from 'lodash/first';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import { humanFileSize } from '../../../utils/FileUtils';
import { AssetFile } from '../../../domain/media';
import axios, { AxiosResponse } from 'axios';
import JSZip from 'jszip';

export function downloadEducationalFolderKit(title: string, resources: MovieAsset[]): void {
    const filesToZip: AssetFile[] = compact(
        map(resources, (movieAsset: MovieAsset): AssetFile | undefined => {
            const file: AssetFile | undefined = first(movieAsset.asset.files);
            return file
                ? {
                      ...file,
                      name: getAssetFilename(movieAsset)!,
                  }
                : undefined;
        })
    );

    const zip = new JSZip();
    Promise.all(
        filesToZip.map(
            (file: AssetFile): Promise<void | AxiosResponse<Blob>> => {
                return axios
                    .get(file.url + '?t=' + Date.now(), {
                        responseType: 'blob',
                    })
                    .catch((_err: Error): void => {});
            }
        )
    ).then((responses: (void | AxiosResponse<Blob>)[]): void => {
        forEach(responses, (response: void | AxiosResponse<Blob>, i: number): void => {
            if (response !== undefined) {
                zip.file(filesToZip[i].name, (response as AxiosResponse<Blob>).data, {
                    binary: true,
                });
            }
        });
        zip.generateAsync({ type: 'blob' }).then((blob): void => {
            const filename = title.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '_education_folder.zip';
            saveAs(blob, filename);
        });
    });
}

export interface MovieSlideProps extends WithT {
    movie: MovieSlideData;
    parentId?: string;

    /* https://redmine.arte-studio.fr/issues/316117
     * The slowest tooltip should only go in the Home page
     */
    isHomeTooltip?: boolean;
    hasDownloadKitButton?: boolean;
    resources?: MovieAsset[];
    i18n?: i18next.i18n;
}

/**
 * A Slide component meant to show a Movie in a react-slick <Slider>.
 * Will show a tooltip when hovered, with an extract of the synopsis
 * and a button to order the movie.
 */

export function MovieSlide({
    movie,
    parentId,
    isHomeTooltip = true,
    hasDownloadKitButton,
    resources,
    t,
    i18n,
}: MovieSlideProps): JSX.Element | null {
    const [canDisplayTooltip, setCanDisplayTooltip] = useState(false);
    useEffect((): void => {
        setCanDisplayTooltip(true);
    }, []);

    if (movie === null) {
        return null;
    }

    const tooltipId = `${parentId}_${movie.id}`;

    // Wahoo this is not pretty
    // This is only displayed on /education page
    let educationalFoldersKitSize: string | null = null;
    if (
        hasDownloadKitButton &&
        i18n !== undefined &&
        resources !== undefined &&
        resources.length > 0 &&
        resources.some((resource): boolean => resource.type === AssetType.EDUCATIONAL_FOLDER)
    ) {
        educationalFoldersKitSize = humanFileSize(
            sumBy(
                resources.filter((resource): boolean => resource.type === AssetType.EDUCATIONAL_FOLDER),
                getAssetSize
            ),
            i18n.language
        );
    }

    return (
        <div>
            <Link href={{ pathname: '/movie', query: { id: movie.id } }}>
                <a>
                    <MoviePoster movie={movie} tooltipId={tooltipId} t={t} />
                </a>
            </Link>
            {canDisplayTooltip &&
                createPortal(
                    <Tooltip
                        id={tooltipId}
                        offset={{ left: -10 }}
                        place={isHomeTooltip ? 'left' : 'right'}
                        type="light"
                        effect="solid"
                        delayShow={isHomeTooltip ? 550 : 150}
                        delayHide={150}
                    >
                        <MovieTooltip movie={movie} t={t}>
                            <p className="MovieSlide-Tooltip-Synopsis">{truncate(movie.synopsis, { length: 140 })}</p>
                            <OrderButton
                                className="MovieSlide-Tooltip-OrderButton"
                                textClassName="MovieSlide-Tooltip-OrderButton-Text"
                                movieId={movie.id}
                                t={t}
                            />
                            {educationalFoldersKitSize !== null && resources !== undefined && resources.length > 0 && (
                                <ActionButton
                                    className="MovieSlide-Tooltip-DownloadButton"
                                    textClassName="MovieSlide-Tooltip-DownloadButton-Text"
                                    customIconPath="/static/img/download.svg"
                                    text={t('education:educationalFolder', { educationalFoldersKitSize })}
                                    onClick={(): void => {
                                        downloadEducationalFolderKit(
                                            movie.title,
                                            resources.filter(
                                                (resource): boolean => resource.type === AssetType.EDUCATIONAL_FOLDER
                                            )
                                        );
                                    }}
                                />
                            )}
                        </MovieTooltip>
                    </Tooltip>,
                    document.querySelector('.App') || document.body
                )}
        </div>
    );
}
