import React, { FunctionComponent } from 'react';
import classNames from 'clsx';
import { Color } from 'chroma-js';
import chroma from 'chroma-js';
import isEmpty from 'lodash/isEmpty';

export interface MovieIllustrationTrimmedInStripsProps {
    // Movie illustration href
    href?: string;
    // Extra optional classname to pass
    className?: string;
    // Color to fill in the strips is there is no illustration. Defaults to black
    fillColor?: Color;
}

function renderPath(fillColor?: Color): JSX.Element {
    return (
        <path
            fill={fillColor ? fillColor.hex() : 'black'}
            d="M80.966.15l27.368 15.752-80.586 139.145-27.37-15.752L80.967.15zm128.77 0l27.369 15.752-80.587 139.145-27.369-15.752L209.736.15zm-85.96 0l27.37 15.752-80.587 139.145-27.37-15.752L123.778.15zm128.771 0l27.369 15.752-80.587 139.145-27.369-15.752L252.547.15zm-85.79 0l27.368 15.752-80.587 139.145-27.369-15.752L166.756.15z"
        />
    );
}

export const MovieIllustrationTrimmedInStrips: FunctionComponent<MovieIllustrationTrimmedInStripsProps> = ({
    href,
    className,
    fillColor,
}: MovieIllustrationTrimmedInStripsProps): JSX.Element | null => {
    const classnames: string = classNames('MovieIllustrationTrimmedInStrips', className);

    return !isEmpty(href) ? (
        <svg className={classnames} viewBox="0 0 280 155">
            <g>
                <clipPath id="movie-illustration-trimmed-in-strips-mask">{renderPath(fillColor)}</clipPath>
            </g>
            <image
                preserveAspectRatio="xMidYMid slice"
                clipPath="url(#movie-illustration-trimmed-in-strips-mask)"
                width="100%"
                height="100%"
                xlinkHref={href}
            />
        </svg>
    ) : (
        <svg className={classnames} viewBox="0 0 280 155">
            <g fill="none" fillRule="evenodd">
                {renderPath(fillColor)}
            </g>
        </svg>
    );
};

MovieIllustrationTrimmedInStrips.defaultProps = {
    fillColor: chroma('black'),
};
