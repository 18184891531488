import React, { useState, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { MovieIllustrationTrimmedInStrips } from '../MovieIllustrationTrimmedInStrips';
import { MovieResourcesKitModal, MovieResourcesKitModalProps } from './MovieResourcesKitModal';
import { humanFileSize } from '../../../utils/FileUtils';
import compact from 'lodash/compact';
import map from 'lodash/map';
import first from 'lodash/first';
import size from 'lodash/size';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import sumBy from 'lodash/sumBy';
import last from 'lodash/last';
import filter from 'lodash/filter';
import snakeCase from 'lodash/snakeCase';
import { ActionButton, ShowMoreButton } from '../../common/Button';
import { useWindowWidth } from '../../../hooks';
import { MoviePageProps } from '../../../pages/movie';
import { getMoviePhotoResourcesUrls } from '../../../domain/utils/MovieUtils';
import { MovieAsset, AssetCategory, Movie, AssetType } from '../../../domain/work';
import { MovieStateData } from '../../../modules/movie';
import { AssetFile } from '../../../domain/media';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios, { AxiosResponse } from 'axios';
import { LoginStatus } from '../../../modules/auth';
import { AppState } from '../../../store/reducers';
import { TFunction } from 'next-i18next';
import classNames from 'clsx';

interface StateProps {
    loginStatus: LoginStatus;
}

export type MovieResourcesKitProps = StateProps & Omit<MoviePageProps, 'screeningStates'>;

export function getAssetSize(movieAsset: MovieAsset): number {
    const file: AssetFile | undefined = first(movieAsset.asset.files);
    return file ? file.size : NaN;
}

function getFullKitFileSize(movie: MovieStateData, language: string): string | null {
    const totalSize = sumBy(movie && movie.resources, getAssetSize);
    return humanFileSize(totalSize, language);
}

function renderResourcesCount(count: number, label: string): JSX.Element | null {
    if (count < 1) {
        return null;
    }

    return (
        <li className="MovieResourcesKit-Stats-List-Item">
            <span className="MovieResourcesKit-Stats-List-Item-Count">{count}</span>
            <span className="MovieResourcesKit-Stats-List-Item-Kind">{label}</span>
        </li>
    );
}

function excludeNonEnglishOrFrenchSubtitles(asset: MovieAsset): boolean {
    if (asset.type === AssetType.SUBTITLE) {
        const file = first(asset.asset.files);
        if (!file) {
            return false;
        }
        return file.name.match(/-(EN|FR).vtt$/) !== null;
    }
    return true;
}

function excludeF4vVideoFiles(asset: MovieAsset): boolean {
    if (asset.type === AssetType.VIDEO) {
        const file = first(asset.asset.files);
        if (!file) {
            return false;
        }
        return file.name.endsWith('.f4v') === null;
    }
    return true;
}

export function excludeResources(asset: MovieAsset): boolean {
    return excludeNonEnglishOrFrenchSubtitles(asset) && excludeF4vVideoFiles(asset);
}

export type AssetsForCategory = Record<AssetCategory, MovieAsset[]>;

export function groupAssetsByCategory(resources: MovieAsset[]): AssetsForCategory {
    return groupBy(
        resources,
        (asset: MovieAsset): AssetCategory => {
            switch (asset.category) {
                case AssetCategory.TECHNICAL:
                case AssetCategory.TECHNICAL_TRAILER:
                case AssetCategory.TECHNICAL_VIDEO:
                    return AssetCategory.TECHNICAL;
                case AssetCategory.MEDIA:
                case AssetCategory.EDUCATIONAL:
                    return asset.category;
                default:
                    return AssetCategory.MEDIA;
            }
        }
    ) as AssetsForCategory;
}

export function getAssetFilename(asset: MovieAsset): string | undefined {
    const file: AssetFile | undefined = first(asset.asset.files);
    if (file == null) {
        return undefined;
    }
    let filename: string = asset.asset.name || file.name;
    if (file.ext && filename.split('.').length < 2) {
        filename = [filename, file.ext].join('.');
    }
    if (asset.category === AssetCategory.TECHNICAL_VIDEO) {
        filename = snakeCase(asset.movie.title) + '-' + filename;
    }
    return filename;
}

function downloadFullKitAsZipFile(movie: MovieStateData): void {
    const filesToZip: AssetFile[] = compact(
        map(filter(movie.resources, excludeResources), (movieAsset: MovieAsset): AssetFile | undefined => {
            const file: AssetFile | undefined = first(movieAsset.asset.files);
            return file
                ? {
                      ...file,
                      name: getAssetFilename(movieAsset)!,
                  }
                : undefined;
        })
    );

    const zip = new JSZip();
    Promise.all(
        filesToZip.map(
            (file: AssetFile): Promise<void | AxiosResponse<Blob>> => {
                return axios
                    .get(file.url + '?t=' + Date.now(), {
                        responseType: 'blob',
                    })
                    .catch((_err: Error): void => {});
            }
        )
    ).then((responses: (void | AxiosResponse<Blob>)[]): void => {
        forEach(responses, (response: void | AxiosResponse<Blob>, i: number): void => {
            if (response !== undefined) {
                zip.file(filesToZip[i].name, (response as AxiosResponse<Blob>).data, {
                    binary: true,
                });
            }
        });
        zip.generateAsync({ type: 'blob' }).then((blob): void => {
            const filename = movie.title.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '_resources_kit.zip';
            saveAs(blob, filename);
        });
    });
}

function renderDownloadButtons(
    movie: Movie,
    loginStatus: LoginStatus,
    fullKitFileSize: string | null,
    setModalIsOpen: (isModalOpen: boolean) => void,
    t: TFunction
): JSX.Element | null {
    if (loginStatus === LoginStatus.ANONYMOUS) {
        return null;
    }
    return (
        <div className="MovieResourcesKit-Download">
            <ActionButton
                className="MovieResourcesKit-Download-Button"
                textClassName="MovieResourcesKit-Download-Button-Text"
                text={
                    fullKitFileSize == null
                        ? t('movie:download_full_resources_kit_unknown_size')
                        : t('movie:download_full_resources_kit', { fullKitFileSize })
                }
                onClick={(): void => {
                    downloadFullKitAsZipFile(movie);
                }}
            />
            <ShowMoreButton
                className="MovieResourcesKit-Download-SeeFullListLink"
                onClick={(): void => setModalIsOpen(true)}
                text={t('see_the_detailed_list')}
            />
        </div>
    );
}

function _MovieResourcesKit(props: MovieResourcesKitProps): JSX.Element | null {
    const [isModalOpen, setModalIsOpen] = useState<boolean>(false);
    useWindowWidth();

    const { i18n, movieState, loginStatus, t } = props;
    const movie = movieState.data;

    if (movie == null || movie.resources == null || movie.resources.length < 1) {
        return null;
    }

    const assetsForCategory: AssetsForCategory = groupAssetsByCategory(filter(movie.resources, excludeResources));

    const fullKitFileSize: string | null = getFullKitFileSize(movie, i18n.language);

    const resourcesKitTitleSvgImageSrc = `/static/img/MovieBrochure/MovieResourcesKit/MovieResourcesKit-Title-${i18n.language}.svg`;

    const mediaResourcesCount: number = size(assetsForCategory[AssetCategory.MEDIA]);
    const technicalResourcesCount: number = size(assetsForCategory[AssetCategory.TECHNICAL]);
    const educationalResourcesCount: number = size(assetsForCategory[AssetCategory.EDUCATIONAL]);

    const modalProps: MovieResourcesKitModalProps = {
        ...props,
        isOpen: isModalOpen,
        onClose: (): void => setModalIsOpen(false),
    };

    const photoResourceUrl: string | undefined = last(getMoviePhotoResourcesUrls(movie ? movie.resources : undefined));

    return (
        <div
            className={classNames('MovieResourcesKit', {
                anonymous: loginStatus === LoginStatus.ANONYMOUS,
            })}
        >
            <MovieIllustrationTrimmedInStrips
                className="MovieResourcesKit-Illustration"
                href={photoResourceUrl}
                fillColor={movie ? movie.mainColor : undefined}
            />
            <div className="MovieResourcesKit-Stats">
                <h2 className="MovieResourcesKit-Stats-Title">
                    <img src={resourcesKitTitleSvgImageSrc} alt="Kit de ressources" />
                </h2>
                <ul className="MovieResourcesKit-Stats-List">
                    {renderResourcesCount(
                        mediaResourcesCount,
                        t('media_resource_short', { count: mediaResourcesCount })
                    )}
                    {renderResourcesCount(
                        technicalResourcesCount,
                        t('technical_resource_label', { count: technicalResourcesCount })
                    )}
                    {renderResourcesCount(
                        educationalResourcesCount,
                        t('educational_resource_label', { count: educationalResourcesCount })
                    )}
                </ul>
            </div>
            {renderDownloadButtons(movie, loginStatus, fullKitFileSize, setModalIsOpen, t)}
            <MovieResourcesKitModal {...modalProps} />
        </div>
    );
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        loginStatus: state.auth.loginStatus,
    };
};

export const MovieResourcesKit = connect(mapStateToProps)(_MovieResourcesKit) as FunctionComponent<MoviePageProps>;
