import React, { SFC, useState } from 'react';
import { ModalDialog, ModalDialogHeader, ModalDialogBody } from '../../common/ModalDialog';
import size from 'lodash/size';
import first from 'lodash/first';
import map from 'lodash/map';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import filter from 'lodash/filter';
import { getMovieColor } from '../../../domain/utils/MovieUtils';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/pro-light-svg-icons';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { TFunction } from 'i18next';
import { TabList, Tab } from '../../common/A11y/TabList';
import classNames from 'clsx';
import {
    AssetsForCategory,
    groupAssetsByCategory,
    MovieResourcesKitProps,
    getAssetFilename,
    getAssetSize,
    excludeResources,
} from './MovieResourcesKit';
import { AssetCategory, MovieAsset } from '../../../domain/work';
import { AssetFile } from '../../../domain/media';
import axios, { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { humanFileSize } from '../../../utils/FileUtils';

library.add(faFileImage, faArrowToBottom);

export interface MovieResourcesKitModalProps extends MovieResourcesKitProps {
    isOpen?: boolean;
    onClose?: () => void;
}

function downloadAsset(asset: MovieAsset, t: TFunction): void {
    const file: AssetFile | undefined = first(asset.asset.files);
    if (file == null) {
        return;
    }
    axios
        .get(file.url + '?t=' + Date.now(), {
            responseType: 'blob',
        })
        .then((response: AxiosResponse<Blob>): void => {
            const filename = getAssetFilename(asset);
            saveAs(response.data, filename);
        })
        .catch((_err: Error): void => {
            alert(t('could_not_find_asset'));
        });
}

function renderAsset(asset: MovieAsset, key: number, locale: string, t: TFunction): JSX.Element {
    return (
        <li key={key} className="MovieResourcesKitModal-Body-Resources-Resource">
            <FontAwesomeIcon
                size="3x"
                className="MovieResourcesKitModal-Body-Resources-Resource-Icon"
                aria-hidden="true"
                icon={faFileImage}
            />
            <div className="MovieResourcesKitModal-Body-Resources-Resource-Data">
                <span className="MovieResourcesKitModal-Body-Resources-Resource-Data-Filename">
                    {getAssetFilename(asset)}
                </span>
                <span className="MovieResourcesKitModal-Body-Resources-Resource-Data-Filesize">
                    {humanFileSize(getAssetSize(asset), locale)}
                </span>
            </div>
            <button
                className="MovieResourcesKitModal-Body-Resources-Resource-DownloadButton"
                onClick={(): void => {
                    downloadAsset(asset, t);
                }}
            >
                <FontAwesomeIcon size="2x" aria-hidden="true" icon={faArrowToBottom} />
            </button>
        </li>
    );
}

function renderResources(
    assetsForCategory: AssetsForCategory,
    category: AssetCategory,
    locale: string,
    t: TFunction
): JSX.Element | null {
    const assets = assetsForCategory[category];

    if (size(assets) < 1) {
        return null;
    }

    return (
        <ul className="MovieResourcesKitModal-Body-Resources">
            {map(
                assets,
                (asset: MovieAsset, key: number): JSX.Element => {
                    return renderAsset(asset, key, locale, t);
                }
            )}
        </ul>
    );
}

function getShortLabelI18nKey(assetCategory: AssetCategory): string {
    return `${assetCategory}_resource_short`;
}

function getLabelI18nKey(assetCategory: AssetCategory): string {
    return `${assetCategory}_resource_label`;
}

function renderCategoryTab(
    tabCategory: AssetCategory,
    assetsForCategory: AssetsForCategory,
    tabPosition: number,
    tabsCount: number,
    activeTabCategory: AssetCategory,
    setActiveTabCategory: React.Dispatch<React.SetStateAction<AssetCategory>>,
    t: TFunction
): JSX.Element {
    const resourcesCount: number = size(assetsForCategory[tabCategory]);

    const active: boolean = activeTabCategory === tabCategory;
    const className: string = classNames('MovieResourcesKitModal-Header-TabList-Tab', {
        active,
    });
    const resourcesCountSuffix = ` (${resourcesCount})`;
    return (
        <Tab
            key={tabPosition}
            ariaLabel={t(getLabelI18nKey(tabCategory))}
            className={className}
            onClick={(): void => {
                setActiveTabCategory(tabCategory);
            }}
            ariaSelected={active}
            ariaPosinset={tabPosition}
            ariaSetsize={tabsCount}
            ariaDisabled={false /*resourcesCount < 1 FIXME */}
        >
            {t(getShortLabelI18nKey(tabCategory))}
            <span className="count">{resourcesCountSuffix}</span>
        </Tab>
    );
}

function renderCategoryTabs(
    categoryFilter: AssetCategory,
    setCategoryFilter: React.Dispatch<React.SetStateAction<AssetCategory>>,
    filesForCategory: AssetsForCategory,
    t: TFunction
): JSX.Element {
    return (
        <TabList className="MovieResourcesKitModal-Header-TabList" orientation="horizontal">
            {[AssetCategory.MEDIA, AssetCategory.TECHNICAL, AssetCategory.EDUCATIONAL].map(
                (category: AssetCategory, idx: number, categories: AssetCategory[]): JSX.Element => {
                    return renderCategoryTab(
                        category,
                        filesForCategory,
                        idx,
                        categories.length,
                        categoryFilter,
                        setCategoryFilter,
                        t
                    );
                }
            )}
        </TabList>
    );
}

export const MovieResourcesKitModal: SFC<MovieResourcesKitModalProps> = ({
    isOpen,
    onClose,
    movieState,
    i18n,
    t,
}: MovieResourcesKitModalProps): JSX.Element | null => {
    const [categoryFilter, setCategoryFilter] = useState<AssetCategory>(AssetCategory.MEDIA);
    const handleClose: () => void = isFunction(onClose) ? onClose : noop;
    const movie = movieState.data;

    if (movie == null) {
        return null;
    }

    const assetsForCategory: AssetsForCategory = groupAssetsByCategory(filter(movie.resources, excludeResources));

    return (
        <ModalDialog
            isOpen={!!isOpen}
            ariaLabel="kit de ressources"
            onEscKeyPress={handleClose}
            onOutsideClick={handleClose}
            transparentOverlayColor={getMovieColor(movie)}
        >
            <ModalDialogHeader className="MovieResourcesKitModal-Header" onClose={handleClose} t={t}>
                <span className="MovieResourcesKitModal-Header-Title">{t('resources_kit_title')}</span>
                {renderCategoryTabs(categoryFilter, setCategoryFilter, assetsForCategory, t)}
            </ModalDialogHeader>
            <ModalDialogBody>{renderResources(assetsForCategory, categoryFilter, i18n.language, t)}</ModalDialogBody>
        </ModalDialog>
    );
};
